import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default {
  install: (app, { router }) => {
    Sentry.init({
      app,
      dsn: "https://62fa162a45bc49d38aaf760ce7327524@o417911.ingest.sentry.io/4504829033971712",
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            "localhost",
            "affiliate.riseworks.io",
            "affiliate.dev-riseworks.io",
            "affiliate.staging-riseworks.io",
            /^\//,
          ],
        }),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      ignoreErrors: [
        "Not connected to a wallet.",
        "Wallet not authorized",
        "TypeError: Failed to fetch",
        "TypeError: NetworkError when attempting to fetch resource.",
        "TypeError: Load failed",
        "TypeError: Importing a module script failed.",
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
    });
    app.provide("sentry", {
      setContext: (n, context) => Sentry.setContext(n, context),
      setUser: (user) => Sentry.setUser(user),
      setTag: (tagName, value) => Sentry.setTag(tagName, value),
      addBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
      captureException: (exception) => Sentry.captureException(exception),
    });
  },
};
