export default function useShareSocial({ url, title, text }) {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedText = encodeURIComponent(text);

  const facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitter = `https://twitter.com/intent/tweet?text=${encodedText}${encodedUrl}`;
  const linkedIn = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
  const telegram = `https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`;
  const email = `mailto:recipient@person.com?subject=${encodedTitle}&body=${encodedText}${encodedUrl}`;
  const whatsapp = `https://api.whatsapp.com/send?text=${encodedText}${encodedUrl}`;
  const reddit = `https://reddit.com/submit?url=${encodedUrl}&title=${encodedText}`;

  return { facebook, twitter, linkedIn, telegram, email, whatsapp, reddit };
}
