import { defineStore } from "pinia";

export const useAffiliatesStore = defineStore("affiliates", {
  state: () => ({
    user: null,
  }),
  getters: {
    isProfileCompleted(state) {
      return state.user?.firstname?.length > 0;
    },
  },
  actions: {
    async createUser() {
      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$execute("affiliates_create_user");
      return new Promise((resolve, reject) => {
        this.$fetch("/affiliates/users", {
          method: "POST",
          query: { recaptchaToken },
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getUser() {
      return new Promise((resolve, reject) => {
        this.$fetch("/affiliates/users", {
          method: "GET",
        })
          .then((resp) => {
            this.user = resp.data;
            localStorage.setItem("email", resp.data.email);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getDashboard() {
      return new Promise((resolve, reject) => {
        this.$fetch("/affiliates/users/dashboard", {
          method: "GET",
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updatePayoutAccount(data) {
      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$execute("affiliates_update_user");
      return new Promise((resolve, reject) => {
        this.$fetch(
          `/affiliates/users/payouts/account?recaptchaToken=${recaptchaToken}`,
          {
            method: "POST",
            body: data,
          }
        )
          .then((resp) => {
            this.user = resp.data;
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getAccounts() {
      return new Promise((resolve, reject) => {
        this.$fetch("/affiliates/users/payouts/account", {
          method: "GET",
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateUser({ user }) {
      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$execute("affiliates_update_user");
      return new Promise((resolve, reject) => {
        this.$fetch("/affiliates/users", {
          method: "PUT",
          body: { ...user },
          query: { recaptchaToken },
        })
          .then((resp) => {
            this.user = resp.data;
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
