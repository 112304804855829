<script setup>
import { computed, ref, watch } from "vue";
import { useClipboard } from "@vueuse/core";
import useShareSocial from "../composables/useShareSocial";

const props = defineProps([
  "twitterText",
  "telegramText",
  "whatsappText",
  "redditTitle",
  "redditText",
  "emailSubject",
  "emailBody",
  "link",
]);

const emit = defineEmits(["close", "copy"]);

const { copy, copied } = useClipboard({ source: props.link });

const opened = ref(true);

watch(opened, (newValue) => {
  if (!newValue) {
    emit("close");
  }
});

const socialOptions = computed(() => {
  return [
    {
      icon: ["fab", "x-twitter"],
      name: "Twitter (X)",
      url: useShareSocial({ url: props.link, text: props.twitterText }).twitter,
    },
    {
      icon: ["fab", "linkedin-in"],
      name: "LinkedIn",
      url: useShareSocial({ url: props.link }).linkedIn,
    },
    {
      icon: ["fab", "telegram"],
      name: "Telegram",
      url: useShareSocial({ url: props.link, text: props.telegramText })
        .telegram,
    },
    {
      icon: ["fab", "facebook-messenger"],
      name: "Messenger",
      url: useShareSocial({ url: props.link }).facebook,
    },
    {
      icon: ["fab", "whatsapp"],
      name: "WhatsApp",
      url: useShareSocial({ url: props.link, text: props.whatsappText })
        .whatsapp,
    },
    {
      icon: ["fab", "reddit-alien"],
      name: "Reddit",
      url: useShareSocial({
        url: props.link,
        text: props.redditText,
      }).reddit,
    },
    {
      icon: "envelope",
      name: "Mail",
      url: useShareSocial({
        url: props.link,
        title: props.emailSubject,
        text: props.emailBody,
      }).email,
    },
  ];
});

function openExternalUrl(social) {
  window.open(social.url, social.name === "Mail" ? "" : "_blank");
}
</script>

<template>
  <RiseDialog
    v-if="!isSupported"
    v-model="opened"
    class="w-full max-w-[440px] rounded-lg bg-white font-serif"
  >
    <div class="flex gap-2 border-b border-gray-200 py-3 pl-6 pr-3">
      <div class="flex-1">
        <div class="text-lg font-semibold">Share your referral link</div>
        <div class="text-sm text-gray-500">
          {{ link }}
        </div>
      </div>
      <div
        class="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full text-gray-500 hover:bg-gray-50 hover:text-gray-600"
        @click="opened = false"
      >
        <font-awesome-icon icon="times" />
      </div>
    </div>
    <div class="p-6">
      <div class="mb-6 flex flex-wrap justify-center gap-3 sm:gap-6">
        <div
          v-for="social in socialOptions"
          :key="social.name"
          class="group relative h-[60px] w-[60px] cursor-pointer p-[1px]"
          @click="openExternalUrl(social)"
        >
          <div
            class="bg-gradient-1 absolute left-0 top-0 h-full w-full rounded-full opacity-50 transition-all group-hover:opacity-80"
          ></div>
          <div
            class="relative flex h-full w-full items-center justify-center rounded-full bg-white/80"
          >
            <font-awesome-icon
              :icon="social.icon"
              class="text-[28px] [&>*]:fill-[url(#gradient_03)]"
            />
          </div>
        </div>
      </div>
      <RiseButton
        class="w-full"
        variant="outline"
        :text="copied ? 'Copied' : 'Copy link'"
        :icon-right="copied ? 'check' : 'copy'"
        @click="copy()"
      />
    </div>
  </RiseDialog>
</template>
