<script setup>
import { useQRCode } from "@vueuse/integrations/useQRCode";
import { format, parse } from "date-fns";

const payAppUrl = import.meta.env.VITE_PAY_APP_URL;
const nodeEnv = import.meta.env.VITE_NODE_ENV;

const affiliatesStore = useAffiliatesStore();
const user = affiliatesStore.user;
const affiliateUrl = computed(() => {
  return nodeEnv === "production"
    ? `https://www.riseworks.io/referral?referral=${user?.affiliate_code}`
    : `${payAppUrl}/invite/${user?.affiliate_code}`;
});
const socialShareOpened = ref(false);

const loading = ref(true);
const dashboardData = ref(null);

function formatDate(date) {
  const parsed = parse(date, "yyyy-MM-dd", new Date());
  return format(parsed, "MMM dd, yyyy");
}

function currency(value) {
  const amount = value > 0 ? value : 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

onMounted(() => {
  affiliatesStore.getDashboard().then((r) => {
    dashboardData.value = r.data;
    loading.value = false;
  });
});

const qrcode = useQRCode(affiliateUrl.value, {
  margin: 0,
  width: 220,
});
const steps = [
  {
    icon: "share",
    title: "Share your unique link",
  },
  {
    icon: "sack-dollar",
    title: `Earn <span class="text-transparent bg-clip-text bg-gradient-1 font-bold">$250 per paying business</span>`,
    description: "and 10% Rise revenue share for 12 months.",
  },
  {
    icon: "hand-holding-dollar",
    title: "Receive each quarter",
  },
];
</script>

<template>
  <div class="grid gap-12 pt-10 md:grid-cols-[220px_1fr]">
    <div>
      <div
        class="pointer-events-none relative mb-3 flex w-full justify-center overflow-hidden md:h-[220px]"
      >
        <img :src="qrcode" alt="QR Code" class="block" />
      </div>
      <a
        class="mb-3 block break-all text-center text-sm font-semibold text-primary-600 hover:underline"
        :href="affiliateUrl"
        target="_blank"
        rel="noopener"
      >
        {{ affiliateUrl }}
      </a>
      <RiseButton
        class="mb-6"
        text="Share Now"
        icon-right="share"
        variant="outline"
        fill
        @click="socialShareOpened = true"
      />
      <div class="text-center text-sm text-gray-500">
        <b>Need help?</b>
        Click the chat icon in the bottom right corner.Or email us
        <a
          class="underline"
          href="mailto:hello@riseworks.io?subject=Affiliate Support"
          >hello@riseworks.io</a
        >.
      </div>
    </div>
    <div>
      <div class="mb-6 text-lg font-bold text-gray-800">How it works</div>
      <div class="steppers">
        <div
          v-for="step in steps"
          :key="step"
          class="step relative grid grid-cols-[50px,_1fr] gap-4 pb-6 before:absolute before:left-[24.5px] before:top-[50px] before:h-full before:w-[1px] before:bg-gray-200 before:content-[''] last:before:content-[initial]"
        >
          <div
            class="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray-100 text-gray-500"
          >
            <font-awesome-icon :icon="step.icon" class="h-[18px] w-[18px]" />
          </div>
          <div class="text-gray-700">
            <div
              class="title mb-1 text-xl font-medium"
              :class="{ 'pt-[10px]': !step.description }"
              v-html="step.title"
            />
            <div v-if="step.description" class="font-normal">
              {{ step.description }}
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://www.riseworks.io/affiliates/terms"
        target="_blank"
        rel="noopener"
        class="mb-6 block text-center text-sm text-gray-900 underline"
      >
        Terms & Conditions
      </a>
      <div
        v-if="loading && dashboardData == null"
        class="roudned h-[120px] animate-pulse rounded-xl bg-gray-200"
      />
      <div
        v-else
        class="bg-gradient-14 relative mb-2 overflow-hidden rounded-lg p-4 text-white"
      >
        <div class="pointer-events-none absolute left-0 top-0 h-full w-full">
          <img
            class="object-none object-right"
            src="@/backgrounds/waves-1.svg"
            alt="Rise Background"
          />
        </div>
        <div class="pointer-events-none absolute -left-10 top-0 h-full w-full">
          <img
            class="object-none object-left"
            src="@/backgrounds/waves-1.svg"
            alt="Rise Background"
          />
        </div>
        <div class="relative mb-1 text-base font-medium">Next Payout Date</div>
        <div>
          {{ formatDate(dashboardData.nextPayment) }}
        </div>
        <div class="relative text-2xl font-bold">
          Amount:
          {{
            currency(dashboardData.payout ? dashboardData.payout.amount_usd : 0)
          }}
        </div>
      </div>
    </div>
    <RiseSocialShare
      v-if="socialShareOpened"
      twitter-text="Revolutionize payroll and empower your web3 workforce with @Rise_Pay! Simplify fiat <> crypto payments & global contractor onboarding compliantly. Book a demo w/ my link and get 2 months FREE! Sign up: "
      telegram-text="Hey, have you explored Rise Works for Web3 workforce payments & compliance? It's incredible. From flexible fiat and crypto payroll to compliant global hiring, they've got it all covered. Use my link and book a demo to enjoy 2 months free! 🚀 Sign up here: "
      whatsapp-text="Hi, I've been using Rise for Web3 workforce payments and it's mind-blowing. Seamless onboarding, payroll flexibility in fiat and crypto, global compliance... they've nailed it all! Try it out using my referral link and get 2 months free after your demo booking. Sign up here: "
      reddit-text="Elevate your #Web3 workforce experience with Rise Works ↗️ From compliant global onboarding to hybrid fiat and crypto payroll, everything's streamlined and built for growing startups, DAOs, agencies & more. Get 2 months FREE when you book a demo using my link. Dive into the future of Web3 payments and compliance now! Sign up here: "
      email-subject="Meet Rise: Streamline global workforce payments in cash or crypto!"
      email-body="Hey, I've discovered Rise Works, a game-changer for Web3-enabled workforce payments and compliance. It combines flexible cash and crypto payments to your team funded from either a fiat bank account or crypto treasury, compliance across 190+ countries, on-chain professional IDs, and much more. Discover a seamless experience, from onboarding to payments, all in one place. And the best part? Use my link to book a demo and get two months free! "
      :link="affiliateUrl"
      @close="socialShareOpened = false"
    />
  </div>
</template>
