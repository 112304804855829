const __pages_import_0__ = () => import("/src/pages/sign-up/index.vue");
const __pages_import_1__ = () => import("/src/pages/sign-up/code.vue");
const __pages_import_2__ = () => import("/src/pages/sign-in/index.vue");
const __pages_import_3__ = () => import("/src/pages/sign-in/code.vue");
const __pages_import_4__ = () => import("/src/pages/sign-in/callback.vue");
const __pages_import_5__ = () => import("/src/pages/sign-in/2fa.vue");
const __pages_import_6__ = () => import("/src/pages/withdraw.vue");
const __pages_import_7__ = () => import("/src/pages/sign-out.vue");
const __pages_import_8__ = () => import("/src/pages/rewards.vue");
const __pages_import_9__ = () => import("/src/pages/profile.vue");
import __pages_import_10__ from "/src/pages/index.vue";

const routes = [{"name":"sign-up","path":"/sign-up","component":__pages_import_0__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"sign-up-code","path":"/sign-up/code","component":__pages_import_1__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"sign-in","path":"/sign-in","component":__pages_import_2__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"sign-in-code","path":"/sign-in/code","component":__pages_import_3__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"sign-in-callback","path":"/sign-in/callback","component":__pages_import_4__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"sign-in-2fa","path":"/sign-in/2fa","component":__pages_import_5__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"withdraw","path":"/withdraw","component":__pages_import_6__,"props":true,"meta":{"requiresAuth":true}},{"name":"sign-out","path":"/sign-out","component":__pages_import_7__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"rewards","path":"/rewards","component":__pages_import_8__,"props":true,"meta":{"requiresAuth":true}},{"name":"profile","path":"/profile","component":__pages_import_9__,"props":true,"meta":{"requiresAuth":true}},{"name":"index","path":"/","component":__pages_import_10__,"props":true,"meta":{"requiresAuth":true}}];

export default routes;