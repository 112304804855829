import { createApp } from "vue";
import "@/styles/base.css";
import { createRouter, createWebHistory } from "vue-router";
import { setupLayouts } from "virtual:generated-layouts";
import generatedRoutes from "virtual:generated-pages";
import App from "./App.vue";

// Local plugins
import clerk from "./plugins/clerk";
import ofetch from "./plugins/ofetch";
import pages from "./plugins/pages";
import pinia from "./plugins/pinia";
import sentry from "./plugins/sentry";
import select from "./plugins/select";

// Generate routes
import NotFound from "./layouts/404.vue";

const routes = setupLayouts(generatedRoutes);
const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routes,
    { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  ],
});

// Vue setup
const app = createApp(App);

app.config.globalProperties.$router = router;

// Install all plugins under `packages/plugins/src` from monorepo root
Object.values(
  import.meta.glob("../../../packages/plugins/src/*.js", { eager: true })
).forEach((i) => app.use(i.default));

app.use(clerk);
app.use(pinia);
app.use(pages, { router });
app.use(sentry, { router });
app.use(ofetch);
app.use(select);
app.mount("#app");
