<script setup>
const affiliatesStore = useAffiliatesStore();
const clerkSessionStore = useClerkSessionStore();
const loading = ref(true);
const router = useRouter();

const menuItems = computed(() => {
  return [
    {
      to: "/",
      icon: "share",
      name: "Share",
      disabled: !affiliatesStore.isProfileCompleted,
    },
    {
      to: "/rewards",
      icon: "sack-dollar",
      name: "Rewards",
      disabled: !affiliatesStore.isProfileCompleted,
    },
    {
      to: "/withdraw",
      icon: "hand-holding-dollar",
      name: "Withdraw",
      disabled: !affiliatesStore.isProfileCompleted,
    },
    {
      to: "/profile",
      icon: "user",
      name: "Profile",
    },
  ];
});

onMounted(async () => {
  try {
    clerkSessionStore.refreshSession();
    await affiliatesStore.getUser();
  } finally {
    if (!affiliatesStore.isProfileCompleted) {
      router.push("/profile");
    }
    loading.value = false;
  }
});
</script>

<template>
  <div
    class="min-w-screen min-h-screen bg-white font-serif md:grid md:grid-cols-[fit-content(480px)_minmax(524px,_1fr)]"
  >
    <div
      class="relative overflow-hidden bg-dashboard-bg px-4 py-6 md:h-full md:w-full md:py-10 md:pl-20 md:pr-[60px]"
    >
      <div class="pointer-events-none absolute left-0 top-0 h-full w-full">
        <lottie-animation
          class="absolute -left-[140px] -top-[227px] !h-auto !w-[1400px] rotate-[160deg] md:-top-[40px]"
          animation-link="/lottie/feathers.json"
        />
      </div>
      <div class="relative">
        <img
          src="@/logos/rise-logo-dark.svg"
          class="mx-auto mb-6 block h-[30px] w-auto md:mx-0 md:mb-12 md:h-[43px]"
        />
        <div
          class="mb-2 bg-gradient-to-r from-[#3CA4E0] to-[#4E62D8] bg-clip-text text-[30px] font-bold text-transparent md:text-[48px]"
        >
          Rise Affiliate
        </div>
        <div class="text-xl font-light text-white opacity-70">
          Get paid to share the future of payments and compliance with your
          clients, colleagues and friends.
        </div>
      </div>
    </div>
    <div
      class="mx-auto h-full w-full max-w-[664px] px-4 pb-7 pt-0 md:px-8 md:pb-8 md:pt-20"
    >
      <div v-if="loading" class="flex justify-center">
        <RiseSpinner class="h-10 w-10" />
      </div>
      <template v-else>
        <div class="flex flex-col-reverse md:flex-col">
          <router-link
            to="/sign-out"
            class="flex cursor-pointer items-center justify-end font-medium hover:text-primary-600 md:mb-4"
          >
            <font-awesome-icon icon="arrow-right-from-bracket" class="mr-2" />
            Sign out
          </router-link>
          <nav
            class="-ml-4 mb-4 flex w-[calc(100%_+_32px)] items-center overflow-x-auto rounded-lg shadow-sm md:mb-0 md:ml-0 md:w-full md:overflow-x-hidden"
          >
            <router-link
              v-for="item in menuItems"
              :key="item"
              :to="item.to"
              class="flex flex-1 items-center justify-center border-r border-r-gray-200 p-4 text-gray-500 last:border-r-0 hover:bg-gray-50"
              active-class="bg-gray-50 !text-gray-900"
              :class="{
                'pointer-events-none bg-gray-50 text-gray-300': item.disabled,
              }"
            >
              <font-awesome-icon
                :icon="item.icon"
                class="mr-3 h-[14px] w-[14px]"
              />
              <span class="text-sm font-medium"> {{ item.name }} </span>
            </router-link>
          </nav>
        </div>
        <RouterView />
      </template>
    </div>
  </div>
</template>
