import { defineStore } from "pinia";
import { useCookies } from "@vueuse/integrations/useCookies";

export type ClerkError = {
  code: "authentication_invalid";
  longMessage: string;
  message: string;
};

export const useClerkSessionStore = defineStore("clerkSession", {
  state: (): { cleared: boolean } => ({
    cleared: false,
  }),
  getters: {
    async needSecondFactor() {
      await this.$clerk?.load();
      if (!this.$clerk?.client) return false;
      return this.$clerk.client.signIn.status === "needs_second_factor";
    },
    async sessionId(): Promise<string> {
      await this.$clerk?.load();
      if (!this.$clerk?.client?.sessions[0]) return "";
      if (!localStorage) return "";
      if (!localStorage.getItem("clerk-session-id")) return "";

      return localStorage.getItem("clerk-session-id") as string;
    },
    async jwt() {
      await this.$clerk?.load();
      if (!this.$clerk.session) return "";

      const jwt = await this.$clerk.session?.getToken({
        template: "User_Template",
      });

      return `Bearer ${jwt}`;
    },
  },
  actions: {
    // isConnected(): boolean {
    //   const clerkUserStore = useClerkUserStore();
    //   return !!clerkUserStore.user?.primaryEmailAddress;
    // },
    async hasSession() {
      await this.$clerk?.load();
      const clientSession = this.$clerk?.client?.sessions[0];
      return !this.cleared && clientSession;
    },
    async refreshSession() {
      await this.$clerk?.load();
      const clientSession = this.$clerk?.client?.sessions[0];
      if (!clientSession) return;
      try {
        await this.$clerk.setSession(clientSession);
        localStorage.setItem("clerk-session-id", clientSession.id);
      } catch (e) {
        const clerkError: { errors: ClerkError[] } | any = e;
        if (clerkError.errors[0].code === "authentication_invalid") {
          this.$router.push("/sign-out");
        }

        throw new Error((e as Error).message);
      }
    },
    async signOut() {
      this.cleared = true;
      const { remove } = useCookies([]);

      await this.$clerk.load();

      if (this.$clerk) {
        await this.$clerk.signOut();
      }

      if (this.$clerk?.client) {
        await this.$clerk.client.destroy();
      }

      await remove("rise-user");
      await remove("__session");
      await remove("__clerk_db_jwt");
      await remove("__client_uat");
      await localStorage.removeItem("clerk-session-id");
      await localStorage.removeItem("vuex");
    },
  },
});
