import { VueReCaptcha } from "vue-recaptcha-v3";
import { ref } from "vue";

export default {
  install: async (app) => {
    app.use(VueReCaptcha, {
      siteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY,
      loaderOptions: {
        autoHideBadge: true,
      },
    });

    const execute = ref(null);

    function updateExecute() {
      execute.value = app.config.globalProperties.$recaptcha;
    }

    app.config.globalProperties.$execute = execute;

    app.provide("recaptcha", {
      execute,
    });

    await app.config.globalProperties.$recaptchaLoaded();

    app.config.globalProperties.$recaptchaInstance.value.hideBadge();

    updateExecute();
  },
};
