export default {
  install: (app, { router }) => {
    const clerkSessionStore = useClerkSessionStore();

    router.beforeEach(async (to) => {
      await clerkSessionStore.refreshSession();
      const hasSession = await clerkSessionStore.hasSession();

      if (to.meta.requiresAuth && !hasSession) {
        return {
          path: "/sign-in",
          query: { redirect: to.fullPath },
        };
      }
    });

    app.use(router);
  },
};
