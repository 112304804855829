import { ofetch } from "ofetch";

export default {
  install: (app) => {
    const router = app.config.globalProperties.$router;
    const apiFetch = ofetch.create({
      baseURL: import.meta.env.VITE_B2B_API_URL,
      retry: 0,
      async onRequest({ options }) {
        const session = app.config.globalProperties.$clerk?.session;

        if (!session) {
          console.log("No auth token. Logging out.");
          router.push("/sign-out");
          return;
        }

        const token = await session.getToken({
          template: "User_Template",
        });

        options.headers = {
          Authorization: `Bearer ${token}`,
        };
      },
      onResponseError({ response }) {
        const sessionExpired = response.status === 403;

        if (sessionExpired) {
          app.config.globalProperties.$toastError(
            "Session expired. Please login again."
          );

          router.push("/sign-out");
          return;
        }

        const multipleErrors = response._data.message?.errors || null;

        if (multipleErrors) {
          const errorsArray = Object.keys(multipleErrors).map((e) => {
            return multipleErrors[e][0];
          });
          app.config.globalProperties.$toastError(errorsArray[0]);
          return;
        }

        app.config.globalProperties.$toastError(
          response._data.message.toString()
        );
      },
    });

    app.config.globalProperties.$fetch = apiFetch;
  },
};
