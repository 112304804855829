export default {
  install: (app) => {
    const filters = {
      formatWalletAddress(address) {
        if (!address) return "";

        const firstSixChars = address.slice(0, 6);
        const lastFourChars = address.slice(-4);

        return `(${firstSixChars}...${lastFourChars})`;
      },
      formatCurrency(amount) {
        if (!amount) return "";

        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
      },
      formatDate(
        string,
        { year = "numeric", month = "long", day = "numeric" } = {}
      ) {
        const date = new Date(string);
        const isDate = date instanceof Date && !isNaN(date);
        if (!isDate) return "";

        return new Intl.DateTimeFormat("en-US", { year, month, day }).format(
          date
        );
      },
    };
    app.config.globalProperties.$filters = filters;
    app.provide("filters", filters);
  },
};
