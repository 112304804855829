import Clerk from "@clerk/clerk-js";

export default {
  install: async (app) => {
    const isLocalDevelopment = import.meta.env.MODE === "development";
    const CLERK_V1_PUBLISHABLE_KEY = import.meta.env
      .VITE_CLERK_V1_PUBLISHABLE_KEY;

    let clerk = null;
    const clerkDevDevPublishableKey =
      "pk_test_c2FmZS1zaGVwaGVyZC04Ni5jbGVyay5hY2NvdW50cy5kZXYk";
    const publishableKey = isLocalDevelopment
      ? clerkDevDevPublishableKey
      : CLERK_V1_PUBLISHABLE_KEY;

    clerk = new Clerk(publishableKey);
    app.provide("clerk", clerk);
    app.config.globalProperties.$clerk = clerk;
    await clerk?.load();
  },
};
