import { createPinia } from "pinia";

const pinia = createPinia();

export default {
  install: (app) => {
    function piniaFetch(context) {
      return {
        ...context.app.config.globalProperties,
      };
    }
    pinia.use(piniaFetch);
    app.use(pinia);
  },
};
